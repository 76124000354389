/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS THEME CUSTOM STYLES
----------------------------------------
!! Copy this file to your project's
   sass root. Don't edit the version
   in node_modules.
----------------------------------------
Custom project SASS goes here.

i.e.
@include u-padding-right('05');
----------------------------------------
*/

.fill {
  min-width: -moz-available;
  min-width: -webkit-fill-available;
  min-width: fill;
}

.maxw-none {
  max-width: none !important;
}

.w-50 {
  width: 50%;
}

.usa-table th {
  max-width: 590px;
  border: 1px solid #11385a;
}

.usa-table tr:nth-child(even) th,
.usa-table tr:nth-child(even) td {
  background-color: #e9e9e9;
}

.usa-table thead th {
  background-color: #11385B;
  color: #fff;
}

/* Social Icons */

.usa-social-link--facebook {
    background-color: #3b5999;
}

.usa-social-link--twitter {
    background-color: #55acee;
}

.usa-social-link--youtube {
    background-color: #FF0000;
}

.usa-social-link--instagram {
  background-color: #f00275;
}

.usa-social-link--flickr {
  background-color: #495ED3;
}

.usa-social-link--rss {
    background-color: #ff6600;
}

.usa-social-link--instagram {
  background-image: url('../img/social-icons/instagram25.svg');
  background-repeat: no-repeat;
}

.usa-social-link--flickr {
  background-image: url('../img/social-icons/flickr25.svg');
  background-repeat: no-repeat;
}

.usa-header__logo {
    @include u-margin-y(1);

    @include at-media('mobile-lg') {
        @include u-margin-y(0);
        @include u-flex('align-center');
    }
}

.usa-header__logo-img {
    margin-right: 1rem;
    max-width: 2.1rem;
    margin-top: 2px;
}

.usa-header__logo-heading {
    font-size: 1rem;
    @include u-margin-y(1);
    line-height: line-height('heading', 1);
}

hr {
    height: 1px;
    border: none;
    color: #ccc;
    background-color: #ccc;
}

#noticias {
    a {
        text-decoration: none;
    }
}

.usa-section--secundary-darker {
    background-color: #b51d09;
    color: color('white');

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: color('accent-cool');
    }

    p {
        color: color('white');
    }

    a {
        color: color('base-lighter');

        &:hover {
            color: color('white');
        }
    }
}

.usa-logo a {
    max-width: 500px;
}

/* Hero Header */

.usa-hero {
  background-position: center center !important;
  background-size: cover !important;
}

.usa-hero__heading--alt {
  color: #fff;
  display: block;
  line-height: 1.2em;
}

.fit-width {
  width: fit-content !important;
}

.usa-section--dark,
.usa-footer__primary-section {
  background: linear-gradient(194deg, rgba(4, 78, 148, 1) 0%, rgba(0, 41, 74, 1) 100%);
}


.flag {
  color: #D93430;
  display: block;
  font-size: .8650735294rem;
  font-weight: 700;
  margin-bottom: .5rem;
  text-transform: uppercase;
}

@include at-media-max('mobile-lg') {
  img.float-right {
    float: none;
    margin: 0px;
    display: block;
    margin-bottom: 20px;
  }
}

@include at-media('desktop') {

    .usa-header__logo-heading {
        @include h3;
    }

    .usa-header__logo-img {
        max-width: units(10);
    }

    .usa-header--extended {
        .usa-logo {
            margin: 1rem 0 1rem;
        }

        .usa-nav__inner,
        .usa-navbar {
            max-width: units('desktop-lg');
        }
    }

    .usa-banner__inner,
    .usa-banner__content {
        max-width: units('desktop-lg');
    }

    .usa-nav__secondary {
        bottom: 5rem;
    }

}

.usa-breadcrumb {

  padding: 0.1em 1em;
  border-radius: 4px;
  background: hsl(300, 14%, 97%);
  margin-bottom: 0.5em;

  li {
    a {
      color: #005ea2;
    }
  }

}

.usa-hero {
  padding-top: 6rem;
  padding-bottom: 6rem;
  position: relative;

  h1 {
    z-index: 10;
    position: relative;
  }
}

.usa-card__heading {
  font-size: 1.1rem;
}

.usa-hero:not(.no-overlay):before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(22,22,22,0.6);
  z-index: 10;
  top: 0;
}

.usa-card__container {
  margin-left: 1rem;
  margin-right: 1rem;
}

.usa-card__container, .card, .sb-wrapper {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
  overflow: hidden;
  border: none;
}

.usa-card__container, .card {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.usa-card__container:hover,
.card:hover {
  transform: translateY(-0.3333333333rem);
  box-shadow: 0 0.5rem 2rem 0 rgba(31, 45, 65, 0.25);
}

.usa-card__container:active,
.card:active {
  transform: none;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.sticky .usa-header--extended {
  box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.10196);
  border-bottom: 1px solid #d2d2d2;
}

.usa-layout-docs__main.post {
  p {
    a {
      color: #005ea2 !important;
    }
  }
}

.usa-layout-docs__sidenav {
  nav {
    position: sticky;
    top: 190px;
  }
}

.usa-footer__logo-heading {
      font-family: 'Merriweather Web',Georgia,'Cambria','Times New Roman',Times,serif;
      font-size: 1.34rem;
      max-width: 400px;
}

.usa-footer {
  hr {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.usa-sign-up {
  .usa-input {
    background: rgba(255, 255, 255, 0.3);
    border: none;
    border-radius: 4px;
  }
}

.usa-footer__primary-section {
  /* background: linear-gradient(143deg, #102e51 46%, #06365B 0) !important; */
}

.usa-sidenav {
  background: #ebe6de;
  padding: 1px 0 5px 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;

  .usa-sidenav__item {
   border-top: none;
  }

  .usa-current,
  .usa-current:hover {
    color: #c61f0c;
    background: #fff !important;
  }

  .usa-current:after {
    background-color: #c61f0c !important;
  }
  a {
    color: #015da2;
    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }
}

.sb-default .sb-wrapper {
  color: #fff;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--button-color) !important
}

.sb-button {
  margin: 0 0.5em 0.5em 0 !important;
}

.sb-default .sb-wrapper.sb-facebook {
  background-color: #4267b2
}

.sb-default .sb-wrapper.sb-twitter {
  background-color: #00acee
}

.sb-default .sb-wrapper.sb-google {
  background-color: #db4437
}

.sb-default .sb-wrapper.sb-mix {
  background-color: #ff8226
}

.sb-default .sb-wrapper.sb-line {
  background-color: #00b900
}

.sb-default .sb-wrapper.sb-linkedin {
  background-color: #006fa6
}

.sb-default .sb-wrapper.sb-pinterest {
  background-color: #bd081c
}

.sb-default .sb-wrapper.sb-reddit {
  background-color: #ff4006
}

.sb-default .sb-wrapper.sb-tumblr {
  background-color: #36465d
}

.sb-default .sb-wrapper.sb-whatsapp {
  background-color: #25d366
}

.sb-default .sb-wrapper.sb-messenger {
  background-color: #0080ff
}

.sb-default .sb-wrapper.sb-telegram {
  background-color: #08c
}

.sb-default .sb-wrapper.sb-xing {
  background-color: #006567
}

.sb-default .sb-wrapper.sb-sms {
  background-color: #20c16c
}

.sb-default .sb-wrapper.sb-email {
  background-color: #ff961c
}

.sb-default .sb-wrapper.sb-vk {
  background-color: #4c75a3
}

.sb-default .sb-wrapper.sb-copy {
  background-color: #607d8b
}

.sb-default .sb-wrapper.sb-print {
  background-color: #765aa2
}

.sb-default .sb-wrapper.sb-expand {
  background-color: #ff6651
}

.sb-default .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  -webkit-filter: none;
  filter: none;
  padding-right: 1em;
  padding-left: 0
}

.sb-default .sb-wrapper.sb-show-icon.sb-show-count .sb-count {
  padding-right: 1em;
  padding-left: 0
}

.sb-default .sb-wrapper.sb-show-text.sb-show-count .sb-text {
  padding-right: .5em
}
